import dynamic from 'next/dynamic'
import {css} from '@emotion/react'
import styled from '@emotion/styled'

// import _Button from '@mui/material/Button'
// import {Theme, ThemeProvider} from '@mui/material'
// import {createTheme} from '@mui/material/styles'


import {Icon} from 'components'
const Image = dynamic(() => import('next/image'))


export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  img?: string
  icon?: string // https://material.io/tools/icons/?style=baseline
  link?: boolean
  light?: boolean
  discrete?: boolean
  secondary?: boolean
  borderless?: boolean
  onClick?: () => void
}



// const Button = ({img, icon, link, light, discrete, secondary, borderless, children, onClick, ...props}: Props) => {
  
//   const theme = (theme: Theme) => createTheme({
//     ...theme,
//     components: {
//       ...theme.components,
//       MuiButtonBase: {
//         defaultProps: {
//           disableRipple: true
//         }
//       },
//       MuiButton: {
//         styleOverrides: {
//           root: {
//             flexShrink: 0,
//             borderRadius: 19,
//             fontFamily: 'inherit',
//             textTransform: 'none'
//           },
//           text: {
//             color: 'gray',
//             '&:hover': {
//               color: '#222',
//               backgroundColor: link ? 'transparent' : 'whitesmoke'
//             }
//           },
//           outlined: {
//             color: 'gray',
//             borderColor: 'silver',
//             '&:hover': {
//               color: '#222',
//               borderColor: 'gray',
//               backgroundColor: 'whitesmoke'
//             }
//           }
//         }
//       }
//     }
//   })

//   return (  
//     <ThemeProvider theme={theme}>
//       <_Button
//         variant={borderless || link ? 'text' : secondary ? 'outlined' : 'contained'}
//         disableElevation={!(secondary || borderless || link)}
//         onClick={onClick}
//       >
//         {children}
//       </_Button>
//     </ThemeProvider>
//   )
// }

const Button = styled(({img, icon, link, light, discrete, secondary, borderless, children, ...props}: Props) => (
  <button type='button' {...props}>
    {img ? <Image src={img} width={16} height={16} alt='' /> : null}
    {icon ? <Icon name={icon} /> : null}
    {children}
  </button>
))`
  
  ${({theme, icon, link, light, discrete, secondary, borderless, children, disabled}) => css`
    
    font: inherit;
    flex-shrink: 0;
    cursor: pointer;
    font-weight: 400;
    gap: ${theme.gap};
    display: inline-flex;
    color: ${theme.colorWhite};
    border-radius: ${theme.radius};
    background-color: ${theme.color};
    border: ${theme.line} solid ${theme.color};
    padding: ${theme.size_0_5} ${theme.size_0_9};
    transition: color 0.2s ease, border-color 0.2s ease, background-color 0.2s ease;
    
    &:hover, &:focus {
      outline: none;
      color: ${theme.colorWhite};
      border-color: ${theme.colorDark};
      background-color: ${theme.colorDark};
    }

    ${secondary && css`
      color: ${theme.colorGray};
      background-color: ${theme.colorWhite};
      border-color: ${theme.colorGrayLight};
      &:hover, &:focus {
        color: inherit;
        border-color: ${theme.colorGray};
        background-color: ${theme.colorGrayAccent};
      }
    `}

    ${light && css`
      color: ${theme.colorWhite};
      border-color: ${theme.colorWhite} !important;
      background-color: ${theme.colorTransparent};
      &:hover, &:focus {
        background-color: ${theme.colorGrayTransparent};
      }
    `}

    ${link && css`
      color: ${theme.colorGray};
      padding: ${theme.size_0_5} 0;
      border-color: ${theme.colorTransparent} !important;
      background-color: ${theme.colorTransparent} !important;
      &:hover, &:focus {
        color: ${theme.colorDark};
        text-decoration: underline;
      }
    `}

    ${discrete && css`
      color: ${theme.colorGrayLight};
    `}

    ${borderless && css`
      text-transform: unset;
      color: ${theme.colorGray};
      border-color: ${theme.colorTransparent} !important;
      background-color: ${theme.colorTransparent};
      &:hover, &:focus {
        color: inherit;
        background-color: ${theme.colorGrayTransparent};
      }
    `}
    
    ${icon && !children && css`
      border: 0;
      line-height: 1;
      padding: ${theme.size_0_5};
      text-decoration: none !important;
    `}

    ${disabled && css`
      opacity: 40%;
      cursor: not-allowed;
      pointer-events: none;
    `}
  `}
`

export default Button
